import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import React from "react";
import { createPortal } from "react-dom";
import { ProductTableContext } from "../../contexts/product-table-context";
import {
  type Product,
  type SaleStatDays,
  saleStat,
  saleStatDays,
} from "../../data/product";
import { preciseCurrencyFormatter } from "../../helpers/format-currency";
import {
  formatCasesQuantity,
  formatUnitsQuantity,
} from "../../helpers/format-quantity";
import { useHijackSearch } from "../../helpers/hijack-search";
import {
  type StringOption,
  stringOptionsToValues,
  useStringOptions,
} from "../../helpers/string-options";
import { Checkbox, MultiSelect, Select } from "../forms";
import { DisplayQuantityToggle } from "../quantity-toggle";
import { SearchInput } from "../search-input";

const useSearchFocus = () => {
  const searchReference = useRef<HTMLInputElement>(null);

  useHijackSearch(() => {
    searchReference.current?.focus();
  });

  return searchReference;
};

export function SearchField() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { query } = filters;
  const searchReference = useSearchFocus();
  return (
    <SearchInput
      placeholder="Filter..."
      value={query}
      onChange={(event) =>
        setFilters({ ...filters, query: event.target.value })
      }
      inputRef={searchReference}
    />
  );
}

export function DepartmentFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { departments } = filters;

  const options: StringOption[] = useMemo(() => {
    const departments = new Set<string>();
    for (const product of products) {
      departments.add(product.department);
    }

    //convert set to array, remove blank items
    return [...departments]
      .filter((department) => department.trim() !== "")
      .sort()
      .map((department) => ({ value: department, label: department }));
  }, [products]);

  const value = useStringOptions(departments);

  const onChange = useCallback(
    (departmentOptions: readonly StringOption[]) => {
      const departments = stringOptionsToValues(departmentOptions);
      setFilters({ ...filters, departments });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Department">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

export function VendorNameFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { vendorNames } = filters;

  const allVendorNames = useMemo(() => {
    const vendorNames = new Set<string>();
    for (const product of products) {
      vendorNames.add(product.vendor_name);
    }

    //convert set to array, drop blank items
    return [...vendorNames]
      .filter((vendorName) => vendorName.trim() !== "")
      .sort();
  }, [products]);

  const options = useStringOptions(allVendorNames);
  const value = useStringOptions(vendorNames);

  const onChange = useCallback(
    (vendorNameOptions: readonly StringOption[]) => {
      const vendorNames = stringOptionsToValues(vendorNameOptions);
      setFilters({ ...filters, vendorNames });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Vendor">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

export function SoldInFilter({
  overrideSoldInDays,
}: {
  overrideSoldInDays?: SaleStatDays[];
}) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { soldIn } = filters;

  const soldInDays = useMemo(() => {
    if (overrideSoldInDays) {
      return overrideSoldInDays;
    }
    return [...saleStatDays];
  }, [overrideSoldInDays]);

  return (
    <FilterWrapper label="Sold In Last">
      <Select
        value={soldIn}
        onChange={(event) =>
          setFilters({
            ...filters,
            soldIn: Number(event.target.value) as SaleStatDays,
          })
        }
      >
        {soldInDays.map((option, index) => (
          <option key={index} value={option}>
            {option} days
          </option>
        ))}
      </Select>
    </FilterWrapper>
  );
}

export function NotSoldInFilter({
  overrideSoldInDays,
}: {
  overrideSoldInDays?: SaleStatDays[];
}) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { notSoldIn, soldIn } = filters;

  const soldInDays = useMemo(() => {
    const options = overrideSoldInDays || [...saleStatDays];
    // when soldIn is set, ensure notSoldIn is less
    return options
      .filter((option) => soldIn === undefined || option < soldIn)
      .sort((a, b) => b - a);
  }, [overrideSoldInDays, soldIn]);

  // if the user changes the soldIn filter so that the current notSoldIn filter is no longer available,
  // reset the notSoldIn filter to the first available option
  useEffect(() => {
    if (notSoldIn && !soldInDays.includes(notSoldIn)) {
      setFilters({ ...filters, notSoldIn: soldInDays[0] });
    }
  }, [soldInDays, notSoldIn, setFilters, filters]);

  return (
    <FilterWrapper label="Not Sold In Last">
      <Select
        value={notSoldIn}
        onChange={(event) =>
          setFilters({
            ...filters,
            notSoldIn: Number(event.target.value) as SaleStatDays,
          })
        }
      >
        {soldInDays.map((option, index) => (
          <option key={index} value={option}>
            {option} days
          </option>
        ))}
      </Select>
    </FilterWrapper>
  );
}

export function TypeFilter({ products }: { products: Product[] }) {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { types } = filters;

  const allTypes = useMemo(() => {
    const types = new Set<string>();
    for (const product of products) {
      types.add(product.type);
    }

    //convert set to array
    return [...types].filter((type) => type.trim() !== "").sort();
  }, [products]);
  const options = useStringOptions(allTypes);

  const value = useStringOptions(types);
  const onChange = useCallback(
    (typeOptions: readonly StringOption[]) => {
      const types = stringOptionsToValues(typeOptions);
      setFilters({ ...filters, types });
    },
    [setFilters, filters],
  );

  return (
    <FilterWrapper label="Type">
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        placeholder="Any"
        isMulti
      />
    </FilterWrapper>
  );
}

function FilterWrapper({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`flex flex-row gap-2 items-center ${className || ""}`}>
      <div className="text-gray-600">{label}</div>
      {children}
    </div>
  );
}

export function HideDisabledFilter() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { hideDisabled } = filters;

  return (
    <FilterWrapper label="Hide Disabled">
      <Checkbox
        invalid={false}
        checked={hideDisabled === true}
        onChange={(event) => {
          setFilters({ ...filters, hideDisabled: event.target.checked });
        }}
      />
    </FilterWrapper>
  );
}

export function TotalValue({ className }: { className?: string }) {
  const { filteredProducts } = useContext(ProductTableContext);
  const totalValue = useMemo(() => {
    const value = filteredProducts.reduce((total, product) => {
      return total + product.last_cost * product.inventory_qty;
    }, 0);
    return preciseCurrencyFormatter(value);
  }, [filteredProducts]);

  return (
    <FilterWrapper label="Total Value" className={className}>
      {totalValue}
    </FilterWrapper>
  );
}

export function DisplayInCasesToggle() {
  const { filters, setFilters } = useContext(ProductTableContext);
  const { displayInCases } = filters;

  return (
    <DisplayQuantityToggle
      displayInCases={displayInCases || false}
      setDisplayInCases={(displayInCases) =>
        setFilters({ ...filters, displayInCases })
      }
    />
  );
}

export function SalesDataPopover({
  children,
  product,
  isCasesView = false,
}: {
  children: React.ReactNode;
  product: Product;
  isCasesView?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    isBottomThird: false,
  });
  const cellRef = useRef<HTMLDivElement>(null);

  // Get position on mouse enter
  const handleMouseEnter = () => {
    if (cellRef.current) {
      const rect = cellRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const isBottomThird = rect.bottom > windowHeight * 0.67;

      setPosition({
        x: rect.right, // Aligned to the right edge of cell
        y: rect.top, // Aligned to the top of cell
        isBottomThird, // Flag to indicate if in bottom third
      });
      setIsOpen(true);
    }
  };

  const pastSalesData = useMemo(() => {
    return saleStatDays
      .filter((period) => period >= 30)
      .map((period) => ({
        period,
        value: isCasesView
          ? formatCasesQuantity(saleStat(product, period), product.case_qty)
          : formatUnitsQuantity(saleStat(product, period)),
      }));
  }, [product, isCasesView]);

  const yearAgoData = useMemo(() => {
    const data = [
      { period: 30, value: product.stats.last_year_next_30 },
      { period: 60, value: product.stats.last_year_next_60 },
      { period: 90, value: product.stats.last_year_next_90 },
    ];

    return data.map((item) => ({
      period: item.period,
      value: isCasesView
        ? formatCasesQuantity(item.value, product.case_qty)
        : formatUnitsQuantity(item.value),
    }));
  }, [product, isCasesView]);

  const hasYearAgoData = useMemo(() => {
    return yearAgoData.some((item) => item.value !== "0");
  }, [yearAgoData]);

  return (
    <div
      ref={cellRef}
      className="relative cursor-pointer"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsOpen(false)}
    >
      {children}

      {isOpen &&
        createPortal(
          <div
            className="fixed z-[1000] w-56 bg-white rounded-md shadow-xl border border-gray-200 p-3 text-sm"
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`,
              ...(position.isBottomThird
                ? {
                    marginTop: "-6px",
                    transform: "translateX(-100%) translateY(-100%)",
                  }
                : {
                    marginTop: "27px",
                    transform: "translateX(-100%)",
                  }),
            }}
          >
            <SalesDataSection
              title="Past Sales"
              data={pastSalesData}
              valueLabel={isCasesView ? "Cases" : "Units"}
            />

            {hasYearAgoData && (
              <SalesDataSection
                title="Upcoming Sales a Year Ago"
                data={yearAgoData}
                valueLabel={isCasesView ? "Cases" : "Units"}
                isLast={true}
              />
            )}
          </div>,
          document.body,
        )}
    </div>
  );
}

// Helper component to display a section of sales data
function SalesDataSection({
  title,
  data,
  valueLabel,
  isLast = false,
}: {
  title: string;
  data: Array<{ period: number; value: string }>;
  valueLabel: string;
  isLast?: boolean;
}) {
  return (
    <>
      <div className="font-medium text-gray-900 mb-1 border-b pb-1">
        {title}
      </div>
      <div
        className={`grid grid-cols-2 gap-x-2 gap-y-1 ${isLast ? "" : "mb-3"}`}
      >
        <div className="text-gray-500 font-medium">Period</div>
        <div className="text-gray-500 font-medium">{valueLabel}</div>

        {data.map(({ period, value }) => (
          <React.Fragment key={period}>
            <div>{period}d</div>
            <div>{value}</div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
