import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router";
import { create } from "zustand";
import { useExistingOrderModal } from "../components/existing-order-modal";
import { AlertContext } from "../contexts/alert-context";
import type { CreateOrder, Order } from "../data/order";
import { OrderStatus } from "../data/order";
import type { OrderItem } from "../data/order-item";
import type { OrderOptions } from "../data/order-options";
import type { Store } from "../data/store";
import type { Vendor } from "../data/vendor";
import { StoreService } from "../services/stores";
import { useSelectedStore } from "./selected-store";

type OrdersState = {
  fetchedStoreId: string;
  orders: Order[];
  loading: boolean;
  setOrders: (orders: Order[], storeId: string) => void;
  setLoading: (loading: boolean) => void;
};

const useOrdersState = create<OrdersState>((set) => ({
  fetchedStoreId: "",
  orders: [],
  loading: true,
  setOrders: (orders: Order[], storeId: string) =>
    set((state) => ({ ...state, orders, fetchedStoreId: storeId })),
  setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
}));

export function useOrders() {
  const { orders, fetchedStoreId, loading, setOrders, setLoading } =
    useOrdersState();

  const { addErrorAlert } = useContext(AlertContext);

  const { getAccessTokenSilently } = useAuth0();

  const storeService = useMemo(
    () => new StoreService(getAccessTokenSilently),
    [getAccessTokenSilently],
  );

  const { selectedStore } = useSelectedStore();

  const createOrder = useCallback(
    (storeId: string, order: CreateOrder) => {
      setLoading(true);
      return storeService
        .createOrder(storeId, order)
        .then((newOrder) => {
          setOrders([...orders, newOrder], storeId);
          return newOrder;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const saveOrderOptions = useCallback(
    (storeId: string, orderId: string, options: OrderOptions) => {
      setLoading(true);
      return storeService
        .saveOrderOptions(storeId, orderId, options)
        .then((updatedOptions) => {
          setOrders(
            orders.map((o) =>
              o.id === orderId ? { ...o, updatedOptions } : o,
            ),
            storeId,
          );
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const saveOrderItem = useCallback(
    (storeId: string, orderId: string, orderItem: OrderItem) => {
      setLoading(true);
      return storeService
        .updateOrderItem(storeId, orderId, orderItem)
        .then((updatedOrderItem) => {
          const updatedOrders = orders.map((o) =>
            o.id === orderId
              ? {
                  ...o,
                  items: o.items.map((i) =>
                    i.product.id === updatedOrderItem.product.id
                      ? updatedOrderItem
                      : i,
                  ),
                }
              : o,
          );
          setOrders(updatedOrders, storeId);

          return updatedOrderItem;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const submitOrder = useCallback(
    (storeId: string, order: Order, method: string) => {
      setLoading(true);
      return storeService
        .submitOrder(storeId, order, method)
        .then((updatedOrder) => {
          setOrders(
            orders.map((o) => (o.id === updatedOrder.id ? updatedOrder : o)),
            storeId,
          );

          return updatedOrder;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const deleteOrder = useCallback(
    (order: Order) => {
      setLoading(true);
      return storeService
        .deleteOrder(order)
        .then(() => {
          setOrders(
            orders.filter((o) => o.id !== order.id),
            order.store_id,
          );
          return true;
        })
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const deleteStaleThenCreateOrder = useCallback(
    (existingOrders: Order[], storeId: string, order: CreateOrder) => {
      setLoading(true);

      return Promise.all(
        existingOrders.map((order) => {
          return storeService.deleteOrder(order).catch((error: Error) => {
            addErrorAlert(error.message);
            throw error;
          });
        }),
      )
        .then(() => {
          return storeService
            .createOrder(storeId, order)
            .then((newOrder) => {
              setOrders(
                [
                  ...orders.filter(
                    (o) => !existingOrders.some((e) => e.id === o.id),
                  ),
                  newOrder,
                ],
                storeId,
              );
              return newOrder;
            })
            .catch((error: Error) => {
              addErrorAlert(error.message);
              throw error;
            });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [storeService, orders, setOrders, setLoading, addErrorAlert],
  );

  const getOrder = useCallback(
    (storeId: string, orderId: string) => {
      return storeService
        .getOrderByStore(storeId, orderId)
        .catch((error: Error) => {
          addErrorAlert(error.message);
          throw error;
        });
    },
    [storeService, addErrorAlert],
  );

  useEffect(() => {
    if (!selectedStore) {
      return;
    }
    if (selectedStore.id === fetchedStoreId) {
      return;
    }

    setLoading(true);
    // flush previous orders so we don't show stale data during fetch
    setOrders([], "");
    storeService
      .getOrdersByStore(selectedStore.id)
      .then((orders) => setOrders(orders, selectedStore.id))
      .catch((error: Error) => {
        addErrorAlert(`Invalid orders: ${error.message}`);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedStore,
    fetchedStoreId,
    storeService,
    setOrders,
    setLoading,
    addErrorAlert,
  ]);

  return {
    orders,
    loading,
    getOrder,
    createOrder,
    saveOrderItem,
    saveOrderOptions,
    submitOrder,
    deleteOrder,
    deleteStaleThenCreateOrder,
  };
}

export function useOrder(storeId: string | undefined, id: string | undefined) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [order, setOrder] = useState<Order | undefined>();
  const { getOrder } = useOrders();

  const loadOrder = useCallback(
    (storeId: string, id: string) => {
      setLoading(true);
      setOrder(undefined);
      return getOrder(storeId, id)
        .then((order) => {
          setOrder(order);
          setError(undefined);
          return order;
        })
        .catch((error: Error) => {
          setError(error.message);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getOrder],
  );

  useEffect(() => {
    if (!storeId || !id) return;

    loadOrder(storeId, id);
  }, [loadOrder, storeId, id]);

  return { loading, order, error };
}

export function useCreateOrder() {
  const [loading, setLoading] = useState(false);
  const { orders, createOrder } = useOrders();
  const navigate = useNavigate();
  const { addErrorAlert } = useContext(AlertContext);
  const { openExistingOrderModal } = useExistingOrderModal();

  const createOrderAndNavigate = useCallback(
    async (store: Store, vendor: Vendor) => {
      setLoading(true);

      try {
        const existingDraftOrders = orders.filter(
          (order) =>
            order.store_id === store.id &&
            order.vendor_id === vendor.vendor_id &&
            order.status === OrderStatus.Draft,
        );

        if (existingDraftOrders.length > 0) {
          openExistingOrderModal(existingDraftOrders);

          return;
        }

        const order = await createOrder(store.id, {
          vendor_id: vendor.vendor_id,
          items: [],
        });
        navigate(`/stores/${store.id}/orders/${order.id}/edit`);
      } catch (error) {
        addErrorAlert(
          error instanceof Error ? error.message : "Failed to create order",
        );
      } finally {
        setLoading(false);
      }
    },
    [orders, createOrder, openExistingOrderModal, navigate, addErrorAlert],
  );

  return {
    loading,
    createOrder: createOrderAndNavigate,
  };
}
