import { XMarkIcon } from "@heroicons/react/20/solid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";

import { FourOhFour } from "../../components/404";
import {
  LinkButton,
  PrimaryButton,
  SmallSecondaryButton,
} from "../../components/buttons";
import {
  actionButtonClass,
  actionButtonInvalidClass,
  buttonInputClass,
  buttonInputInvalidClass,
  inputClass,
  inputInvalidClass,
} from "../../components/form-styles";
import { Field, Section } from "../../components/forms";
import { Page } from "../../components/page";
import { Spinner } from "../../components/spinner";
import { AlertContext } from "../../contexts/alert-context";
import { StoreContext } from "../../contexts/store-context";
import type { Store } from "../../data/store";

/**
 * react-hook-form does not support arrays of primitives, only objects. So we have to transform the emails into objects
 * with the email in the `value` property, and then transform these back in our onSubmit function.
 *
 * https://github.com/orgs/react-hook-form/discussions/7586
 */
type FormValues = {
  name: string;
  emails: { value: string }[];
};

// form validation rules
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Store name is required"),
  emails: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
      }),
    )
    .required(),
});

// tiny wrapper to let the EditStore component just deal with the success case of having a store
function Edit() {
  const { id } = useParams();
  const { stores, loading } = useContext(StoreContext);
  const store = useMemo(() => {
    return stores.find((store) => store.id === id);
  }, [id, stores]);

  if (loading) {
    return <Spinner />;
  }
  if (!id || !store) {
    return <FourOhFour />;
  }
  return <EditStore store={store} />;
}

function EditStore({ store }: { store: Store }) {
  const { addErrorAlert, addSuccessAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { updateStore } = useContext(StoreContext);

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
    control,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...store,
      emails: store.emails.map((email) => ({ value: email })) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "emails",
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      const updatedStore = {
        ...store,
        ...data,
        emails: data.emails.map((email) => email.value),
      };
      updateStore(updatedStore)
        .then(() => {
          addSuccessAlert("Store updated", { keepAfterRouteChange: true });
          navigate(`/stores/${updatedStore.id}/`);
        })
        .catch(addErrorAlert);
    },
    [updateStore, store, navigate, addSuccessAlert, addErrorAlert],
  );

  return (
    <Page title="Edit Store">
      <form
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset()}
        className="flex flex-col gap-2"
      >
        <div className="h-4" />
        <Field label="Owner Name" name="owner_name">
          {store.owner_first_name} {store.owner_last_name}
        </Field>
        <Field
          label="Store Name"
          name="name"
          errorMessage={errors.name?.message}
        >
          <input
            {...register("name")}
            placeholder="Mary's Liquor"
            className={errors.name ? inputInvalidClass : inputClass}
          />
        </Field>
        <Section>Emails</Section>
        {fields.map((field, index) => (
          <Field
            label=""
            name={`emails.${index}.value`}
            errorMessage={errors.emails?.[index]?.value?.message}
            key={field.id}
          >
            <div className="mt-2 flex rounded-md shadow-xs">
              <div className="relative flex grow items-stretch focus-within:z-10">
                <input
                  key={field.id}
                  {...register(`emails.${index}.value`)}
                  className={
                    errors.emails?.[index]?.value?.message
                      ? buttonInputInvalidClass
                      : buttonInputClass
                  }
                  placeholder="jane@example.com"
                  type="email"
                />
              </div>
              <button
                type="button"
                className={
                  errors.emails?.[index]?.value?.message
                    ? actionButtonInvalidClass
                    : actionButtonClass
                }
                onClick={() => remove(index)}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>
          </Field>
        ))}
        <div>
          <SmallSecondaryButton
            type="button"
            onClick={() => append({ value: "" })}
          >
            Add Email
          </SmallSecondaryButton>
        </div>
        <div className="flex gap-2 justify-center pt-4">
          <PrimaryButton type="submit" disabled={formState.isSubmitting}>
            {formState.isSubmitting && <Spinner />}
            Update
          </PrimaryButton>
          <LinkButton to={`/stores/${store.id}/`}>Cancel</LinkButton>
        </div>
      </form>
    </Page>
  );
}

export { Edit };
