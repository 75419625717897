import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { CSVLink } from "react-csv";
import type { LinkProps } from "react-csv/components/Link";
import type { Product } from "../data/product";
import { Actions } from "./product-table/product-column-definitions";
import type { ColumnDefinition } from "./virtualized-table/column-definition";

export interface CSVLinkProps
  extends Omit<LinkProps, "data" | "headers" | "className"> {
  products: Product[];
  columns: ColumnDefinition<Product>[];
}

export const ProductCSVLink: React.FC<CSVLinkProps> = (properties) => {
  const csvData = useMemo(
    () =>
      properties.products.map((product) =>
        properties.columns
          .filter((c) => c !== Actions)
          .reduce(
            (row, column) =>
              Object.assign(row, { [column.label]: column.toString(product) }),
            {} as { [key: string]: string },
          ),
      ),
    [properties.columns, properties.products],
  );

  return (
    <CSVLink
      className="py-2 px-4 bg-emerald-500 text-white rounded-sm hover:bg-emerald-700 transition duration-150 disabled:bg-gray-400 disabled:cursor-not-allowed"
      data={csvData}
      filename={properties.filename}
    >
      <ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
    </CSVLink>
  );
};
