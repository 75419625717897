import { Spinner } from "./spinner";

export const Loading = () => (
  <div className="w-full h-full grid items-center justify-center">
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden bg-white shadow-lg ring-1 ring-black/5 grid grid-flow-col items-center justify-center gap-2 border rounded-sm pl-3 pr-1 py-3">
      <span className="text-lg font-medium text-gray-900">Loading</span>
      <Spinner sizeClass="w-8 h-8" />
    </div>
  </div>
);

export const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 w-full h-full bg-gray-900/75 z-50 flex items-center justify-center">
      <Loading />
    </div>
  );
};
