import { useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  FixedSizeList as List,
  type ListChildComponentProps,
  type ListOnScrollProps,
} from "react-window";
import { useWindowDimensions } from "../../helpers/window-dimension";
import { NoResults } from "../no-results";
import type { ColumnDefinition } from "./column-definition";

export type ColumnLayout<T> = {
  definition: ColumnDefinition<T>;
  width: number;
};

type CellProperties<T> = {
  column: ColumnLayout<T>;
  item: T;
  rowIndex: number;
};

export const defaultCellClassName =
  "grid px-1 py-1 text-xs text-gray-700 truncate text-clip content-center";

export function Cell<T>({ column, item, rowIndex }: CellProperties<T>) {
  const text = useMemo(() => column.definition.toString(item), [column, item]);

  return (
    <div
      style={{ width: column.width }}
      className={column.definition.className || defaultCellClassName}
      title={column.definition.canOverflow ? text : undefined}
    >
      {column.definition.renderer?.(item, text, rowIndex)}
      {!column.definition.renderer && text}
    </div>
  );
}

type DataWithLayout<T> = {
  layout: ColumnLayout<T>[];
  items: T[];
};

function useLayout<T>(columnDefinitions: ColumnDefinition<T>[]) {
  const { width: windowWidth } = useWindowDimensions();
  const layout = useMemo(() => {
    const mininumWidth = columnDefinitions.reduce(
      (sum, column) => sum + column.minWidth,
      16, // Account for padding
    );

    if (windowWidth < mininumWidth) {
      return columnDefinitions.map((column) => ({
        definition: column,
        width: column.minWidth,
      }));
    }

    const budget = windowWidth - mininumWidth;
    const expandableWidth = columnDefinitions.reduce(
      (sum, column) => sum + (column.canOverflow ? column.minWidth : 0),
      0,
    );

    return columnDefinitions.map((column) => ({
      definition: column,
      width: column.canOverflow
        ? column.minWidth + (budget * column.minWidth) / expandableWidth
        : column.minWidth,
    }));
  }, [windowWidth, columnDefinitions]);

  return { layout, windowWidth };
}

export type HeaderCellRenderer<T> = (
  column: ColumnLayout<T>,
) => React.ReactNode;

export function ColumnLabel<T>(column: ColumnLayout<T>): React.ReactNode {
  return <>{column.definition.label}</>;
}

export type RowProperties<T> = ListChildComponentProps<DataWithLayout<T>>;

export type RowRenderer<T> = (properties: RowProperties<T>) => React.ReactNode;

function Row<T>({ index, style, data }: RowProperties<T>): React.ReactNode {
  const item = useMemo(() => data.items[index], [data.items, index]);
  return (
    <div
      style={style}
      key={index}
      className={`grid grid-flow-col divide-x divide-gray-200 border-b border-transparent hover:border-slate-300 ${
        index % 2 === 0 ? "bg-slate-100" : "bg-white"
      }`}
    >
      {data.layout.map((column) => (
        <Cell
          column={column}
          item={item}
          key={column.definition.label}
          rowIndex={index}
        />
      ))}
    </div>
  );
}

type Properties<T> = {
  items: T[];
  columns: ColumnDefinition<T>[];
  rowRenderer?: RowRenderer<T>;
  headerRenderers?: HeaderCellRenderer<T>[];
  rowHeight?: number;
  listReference?: React.Ref<List>;
  onScroll?: ((properties: ListOnScrollProps) => void) | undefined;
};

export function VirtualizedTable<T>({
  items,
  columns,
  rowRenderer = Row,
  headerRenderers = [ColumnLabel],
  rowHeight = 25,
  listReference,
  onScroll,
}: Properties<T>) {
  const { layout, windowWidth } = useLayout(columns);
  const data = useMemo(() => ({ layout, items }), [items, layout]);

  if (items.length === 0) {
    return <NoResults label="No matching items." />;
  }

  return (
    <div className="w-full h-full overflow-x-auto font-mono">
      <div
        style={{ width: windowWidth }}
        className="h-full grid grid-rows-[auto_1fr] px-2"
      >
        <div className="border-b-2">
          {headerRenderers.map((headerRendererer, index) => (
            <div
              key={`header-${index}`}
              className="grid grid-flow-col border-t border-gray-200 divide-x divide-gray-200"
            >
              {layout.map((column) => (
                <div
                  key={column.definition.label}
                  style={{ width: column.width }}
                  className="grid content-end px-1 py-2 text-left text-sm font-semibold text-gray-900"
                >
                  {headerRendererer(column)}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div>
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={listReference}
                height={height}
                itemCount={data.items.length}
                itemData={data}
                itemSize={rowHeight}
                width={width}
                onScroll={onScroll}
                overscanCount={30}
              >
                {rowRenderer}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
}
