import * as Yup from "yup";
import { fixGoDate } from "../helpers/fix-date";
import { ProductPricingSchema } from "./product-pricing";
import { ProductStatSchema } from "./product-stat";

// form validation rules
export const ProductSchema = Yup.object().shape({
  id: Yup.string().required(),
  store_id: Yup.string().required(),
  vendor_id: Yup.string().defined(),

  vendor_name: Yup.string().defined(),
  brand: Yup.string().defined(),
  description: Yup.string().defined(),
  department: Yup.string().defined(),
  item_code: Yup.string().defined(),
  barcode: Yup.string().defined(),
  last_cost: Yup.number().defined(),
  price: Yup.number().defined(),
  type: Yup.string().defined(),
  size: Yup.string().defined(),
  size_ounces: Yup.number().defined(),
  case_qty: Yup.number().defined(),
  standard_qty: Yup.number().defined(),
  order_lot: Yup.string().defined(),
  inventory_qty: Yup.number().defined(),
  stats: ProductStatSchema.required(),
  min_qoh: Yup.number(),
  disabled: Yup.boolean().required(),
  auto_disabled: Yup.boolean(),
  disabled_toggled_at: Yup.date().nullable().transform(fixGoDate),
  last_received: Yup.date().nullable().transform(fixGoDate),
  last_sold: Yup.date().nullable().transform(fixGoDate),
  notes: Yup.string().defined(),

  pricing: ProductPricingSchema.required(),

  updated_at: Yup.date().required(),
});

export type Product = Yup.InferType<typeof ProductSchema>;

export const saleStatDays = [1, 7, 14, 30, 60, 90, 180, 365] as const;
export type SaleStatDays = (typeof saleStatDays)[number];

export function saleStat(p: Product, days: SaleStatDays): number {
  return p.stats[`last_${days}`];
}

export function backgroundClass(index: number, product: Product): string {
  if (product.inventory_qty < 0) {
    return "bg-red-100";
  }

  const name = product.brand + product.description;

  if (name.includes("(S)") || name.includes("SEASONAL")) {
    return "bg-yellow-100";
  }

  if (
    name.includes("(K)") ||
    name.includes("KEG") ||
    name.includes("1/6 K") ||
    name.includes("1/4 K") ||
    name.includes("1/2 K")
  ) {
    return "bg-green-100";
  }

  if (name.includes("ALLOCATED") || name.includes("(A)")) {
    return "bg-orange-100";
  }

  if (name.includes("BRIDGEBUY") || /\(B[1-3]?(A|G)?\)/.test(name)) {
    return "bg-blue-100";
  }

  if (name.includes("(P)")) {
    return "bg-purple-100";
  }

  return index % 2 === 0 ? "bg-slate-100" : "bg-white";
}

export function caseDiscount(product: Product) {
  if (!product.pricing.future_cost || !product.pricing.current_cost) {
    return 0;
  }

  const savings = product.pricing.future_cost - product.pricing.current_cost;
  if (savings > -0.01 && savings < 0.01) {
    return 0;
  }

  return savings;
}

export function volumeDiscount(product: Product, units: number) {
  if (!product.pricing.volume_discounts || !product.pricing.current_cost) {
    return 0;
  }

  for (let i = product.pricing.volume_discounts.length - 1; i >= 0; --i) {
    const discount = product.pricing.volume_discounts[i];

    if (units >= discount.min_units) {
      const savings = product.pricing.current_cost - discount.cost;
      if (savings > -0.01 && savings < 0.01) {
        return 0;
      }

      return savings;
    }
  }

  return 0;
}

export function hasVolumeDiscount(product: Product) {
  if (!product.pricing.volume_discounts || !product.pricing.current_cost) {
    return false;
  }

  for (const discount of product.pricing.volume_discounts) {
    const savings = product.pricing.current_cost - discount.cost;
    if (savings > -0.01 && savings < 0.01) {
      continue;
    }

    return true;
  }

  return false;
}
