import { useMemo } from "react";
import {
  type RowProperties,
  Cell as VirtualizedCell,
} from "../../components/virtualized-table";
import { type Product, backgroundClass } from "../../data/product";
import { useRowHighlighting } from "../../hooks/row-highlighting";

export function RowRenderer({
  index,
  style,
  data,
}: RowProperties<Product>): React.ReactNode {
  const item: Product = useMemo(() => data.items[index], [data.items, index]);
  const { onRowClick, isHighlighted } = useRowHighlighting(item.id);

  const className = useMemo(() => {
    const baseClassName =
      "grid grid-flow-col divide-x divide-gray-200 border-b-2 border-transparent hover:border-slate-500 ";
    const bgClassName = backgroundClass(index, item);

    const borderClassName = isHighlighted
      ? "border-y-[3px]! border-yellow-500"
      : "border-b-2 border-transparent";

    return `${baseClassName} ${bgClassName} ${borderClassName}`;
  }, [index, item, isHighlighted]);
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: we don't want to highlight lines when people are using the keyboard
    <div style={style} key={index} className={className} onClick={onRowClick}>
      {data.layout.map((column) => (
        <VirtualizedCell
          column={column}
          item={item}
          key={column.definition.label}
          rowIndex={index}
        />
      ))}
    </div>
  );
}
