import { useCallback } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { create } from "zustand";
import { AlertContext } from "../contexts/alert-context";
import type { Order } from "../data/order";
import { useOrders } from "../hooks/orders";
import { DangerButton, PrimaryButton } from "./buttons";

type OrderModalState = {
  showExistingOrderModal: boolean;
  existingOrders: Order[];
  openExistingOrderModal: (orders: Order[]) => void;
  dismissExistingOrderModal: () => void;
};

const useOrderModalState = create<OrderModalState>((set) => ({
  showExistingOrderModal: false,
  existingOrders: [],
  openExistingOrderModal: (orders: Order[]) =>
    set({ showExistingOrderModal: true, existingOrders: orders }),
  dismissExistingOrderModal: () =>
    set({ showExistingOrderModal: false, existingOrders: [] }),
}));

export function useExistingOrderModal() {
  const navigate = useNavigate();
  const { addErrorAlert } = useContext(AlertContext);
  const { deleteStaleThenCreateOrder } = useOrders();
  const {
    showExistingOrderModal,
    existingOrders,
    openExistingOrderModal,
    dismissExistingOrderModal,
  } = useOrderModalState();

  const openMostRecentOrder = useCallback(async () => {
    if (existingOrders.length === 0) {
      return;
    }

    try {
      const mostRecentOrder = existingOrders.sort(
        (a, b) => b.updated_at.getTime() - a.updated_at.getTime(),
      )[0];
      navigate(
        `/stores/${mostRecentOrder.store_id}/orders/${mostRecentOrder.id}/edit`,
      );
    } catch (error) {
      addErrorAlert(
        error instanceof Error
          ? error.message
          : "Failed to handle existing orders",
      );
    }

    dismissExistingOrderModal();
  }, [existingOrders, navigate, addErrorAlert, dismissExistingOrderModal]);

  const deleteExistingOrdersAndCreateNew = useCallback(async () => {
    if (existingOrders.length === 0) {
      return;
    }

    try {
      const order = await deleteStaleThenCreateOrder(
        existingOrders,
        existingOrders[0].store_id,
        {
          vendor_id: existingOrders[0].vendor_id,
          items: [],
        },
      );
      navigate(`/stores/${order.store_id}/orders/${order.id}/edit`);
    } catch (error) {
      addErrorAlert(
        error instanceof Error
          ? error.message
          : "Failed to handle existing orders",
      );
    }

    dismissExistingOrderModal();
  }, [
    existingOrders,
    navigate,
    addErrorAlert,
    deleteStaleThenCreateOrder,
    dismissExistingOrderModal,
  ]);

  return {
    existingOrders,
    showExistingOrderModal,
    openExistingOrderModal,
    dismissExistingOrderModal,
    openMostRecentOrder,
    deleteExistingOrdersAndCreateNew,
  };
}

export function ExistingOrderModal() {
  const {
    existingOrders,
    openMostRecentOrder,
    deleteExistingOrdersAndCreateNew,
  } = useExistingOrderModal();

  const orderCount = existingOrders.length;
  const mostRecentOrder = existingOrders.sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  )[0];

  return (
    <div className="max-w-96">
      <div className="pb-8">
        <p className="pb-2">
          {orderCount === 1
            ? "There is an existing draft order for this vendor. "
            : `There are ${orderCount} existing draft orders for this vendor. `}
          Would you like to:
        </p>
        <ul className="list-disc pl-6">
          <li>
            Open the most recent order (updated{" "}
            {new Date(mostRecentOrder.updated_at).toLocaleDateString()})
          </li>
          <li>
            {orderCount === 1
              ? "Cancel the existing order "
              : `Cancel all ${orderCount} existing orders `}
            and create a new one. Any updates to the existing order(s) will be
            lost.
          </li>
        </ul>
      </div>
      <div className="flex justify-end gap-2">
        <DangerButton onClick={deleteExistingOrdersAndCreateNew}>
          Cancel &amp; Create New
        </DangerButton>
        <PrimaryButton onClick={openMostRecentOrder}>
          Open Existing
        </PrimaryButton>
      </div>
    </div>
  );
}
