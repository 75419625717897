import { useContext } from "react";

import { DownloadUploaderButton } from "../../components/download-uploader-button";
import { LoadingOverlay } from "../../components/loading";
import { NoResults } from "../../components/no-results";
import {
  DepartmentFilter,
  DisplayInCasesToggle,
  HideDisabledFilter,
  SearchField,
  TypeFilter,
  VendorNameFilter,
} from "../../components/product-table/components";
import {
  Actions,
  Barcode,
  Brand,
  CaseQty,
  DOH,
  Department,
  Description,
  ItemCode,
  LastCost,
  LastReceived,
  LastSold,
  MarginDollars,
  MarginPercentage,
  OrderLot,
  Price,
  ProductName,
  QOH,
  QOHCases,
  Reorder,
  Size,
  SizeOunces,
  StdQty,
  Type,
  VendorName,
  getCasesSoldColumn,
  getUnitsSoldColumn,
} from "../../components/product-table/product-column-definitions";
import { RowRenderer } from "../../components/product-table/row-renderer";
import { Toolbar } from "../../components/toolbar";
import { VirtualizedTable } from "../../components/virtualized-table";
import {
  type ColumnSort,
  multiSort,
} from "../../components/virtualized-table/column-sort";
import { ProductTableContext } from "../../contexts/product-table-context";
import { StoreProductContext } from "../../contexts/store-product-context";
import type { Product } from "../../data/product";
import {
  type Filters,
  useFilteredProducts,
  useSortedProducts,
} from "../../hooks/products";

const getColumns = ({ displayInCases }: { displayInCases?: boolean }) => [
  VendorName,
  ItemCode,
  Barcode,
  ProductName,
  Department,
  Type,
  Size,
  CaseQty,
  StdQty,
  OrderLot,
  LastCost,
  Price,
  MarginPercentage,
  MarginDollars,
  displayInCases ? getCasesSoldColumn(7) : getUnitsSoldColumn(7),
  displayInCases ? getCasesSoldColumn(14) : getUnitsSoldColumn(14),
  displayInCases ? getCasesSoldColumn(30) : getUnitsSoldColumn(30),
  displayInCases ? getCasesSoldColumn(60) : getUnitsSoldColumn(60),
  displayInCases ? getCasesSoldColumn(90) : getUnitsSoldColumn(90),
  displayInCases ? getCasesSoldColumn(180) : getUnitsSoldColumn(180),
  displayInCases ? getCasesSoldColumn(365) : getUnitsSoldColumn(365),
  displayInCases ? QOHCases : QOH,
  DOH,
  Reorder,
  LastReceived,
  LastSold,
  Actions,
];

const defaultFilters: Filters = {
  query: "",
  departments: [],
  vendorNames: [],
  types: [],
  displayInCases: false,
};

const sortColumns: ColumnSort<Product>[] = [
  {
    column: VendorName,
    direction: "asc",
  },
  {
    column: Department,
    direction: "asc",
  },
  {
    column: SizeOunces,
    direction: "desc",
  },
  {
    column: Brand,
    direction: "asc",
  },
  {
    column: Description,
    direction: "asc",
  },
];

export function Products() {
  const { loading, saving, products } = useContext(StoreProductContext);
  const { filters, setFilters, filteredProducts } = useFilteredProducts(
    products,
    defaultFilters,
  );
  const columns = getColumns({ displayInCases: filters.displayInCases });

  const sortedProducts = useSortedProducts(
    filteredProducts,
    multiSort(sortColumns),
  );

  if (!loading && products.length === 0) {
    return (
      <NoResults label="You haven't imported any products yet.">
        <DownloadUploaderButton />
      </NoResults>
    );
  }

  return (
    <>
      <ProductTableContext.Provider
        value={{ filters, setFilters, filteredProducts }}
      >
        <Toolbar>
          <VendorNameFilter products={products} />
          <DepartmentFilter products={products} />
          <TypeFilter products={products} />
          <HideDisabledFilter />
          <DisplayInCasesToggle />
          <SearchField />
          <div className="rounded-md bg-gray-300 py-1.5 px-2.5 border border-transparent text-sm transition-all shadow-xs">
            {filteredProducts.length}
          </div>
        </Toolbar>
      </ProductTableContext.Provider>
      <VirtualizedTable
        items={sortedProducts}
        columns={columns}
        rowRenderer={RowRenderer}
      />
      {(loading || saving) && <LoadingOverlay />}
    </>
  );
}
