/**
 * Format helpers for quantity values throughout the application.
 * These ensure consistent formatting of units and cases.
 */

/**
 * Formats a units quantity with thousand separators
 */
export function formatUnitsQuantity(value: number): string {
  return value.toLocaleString("en-US");
}

/**
 * Formats a cases quantity with 1-2 decimal places and thousand separators
 */
export function formatCasesQuantity(value: number, caseQty: number): string {
  if (caseQty === 0) {
    return "N/A";
  }

  return (value / caseQty).toLocaleString("en-US", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
}
