import type { ReactNode } from "react";

export const WidgetContainer = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow-sm sm:p-6">
      <dt className="truncate text-xl font-bold text-gray-700">{title}</dt>
      {children}
    </div>
  );
};
