import { useSelectedStore } from "../hooks/selected-store";
import { LastImport } from "./dashboard/last-import";

const Footer = () => {
  const { selectedStore } = useSelectedStore();

  return (
    <footer className="bg-gray-100 p-3 grid grid-cols-3">
      <p className="text-sm text-gray-500">
        © 2025 Liquor Store Tech, LLC. All rights reserved
      </p>
      <p className="justify-self-center">
        Get in touch –{" "}
        <a href="mailto:hello@liquorstore.tech" className="text-emerald-500">
          hello@liquorstore.tech
        </a>
      </p>
      {selectedStore && <LastImport store={selectedStore} />}
    </footer>
  );
};

export default Footer;
